.search {
    flex-grow: 1;
    display: flex;
}

.search__form {
    width: 100%;
    display: flex;
    color: var(--black);
    border-radius: 8px;
    font-size: 14px;
    background-color: var(--white);
}

.search__input {
    padding: 15px 16px;
    flex-grow: 1;
}

.search__button {
    margin-left: auto;
    padding: 15px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
}

.search__select {
    position: relative;
}

.search__select::after {
    content: '';
    position: absolute;
    right: 0;
    top: 8px;
    bottom: 8px;
    width: 1px;
    background-color: #e8e8ec;
    ;
}

.search__select-button {
    padding: 15px 20px 14px 20px;
    display: flex;
    align-items: center;
    gap: 0 8px;
    font-weight: 500;
}

.search__select-button span,
.search__select-button svg {
    pointer-events: none;
    flex-shrink: 0;
}

.search__list {
    padding: 10px 24px;
    margin-top: 5px;
    /* left: 0; */
    border-radius: 10px;
    background-color: #fff;
    /* bottom: -10px; */
    /* transform: translateY(100%); */
    display: flex;
    flex-direction: column;
    gap: 8px 0;
    text-align: start;
    transition: .3s all ease-in;
    z-index: 10;
}


.search__list::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid var(--white);
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translate(-50%, -100%);
}



.search__item-button {
    cursor: pointer;
    width: 100%;
    padding: 4px 0;
    text-align: start;
}


.search__link {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    border-radius: 8px;
    margin-left: 12px;
}

.search__link-m {
    display: none;
}


@media (max-width:720px) {
    .search__link {
        margin: 0;
        display: none;
    }

    .search__link-m {
        display: flex;
        margin-left: auto;
    }
}

@media (max-width:480px) {
    .search__form {
        font-size: 12px;
    }

    .search__select-button {
        padding: 16px 8px 16px 12px;
    }

    .search__input {
        width: 100px;
        padding: 8px 12px;
    }

    .search__select-button svg {
        width: 12px;
        height: 12px;
    }
}