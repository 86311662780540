.button {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 4px;
    background-color: var(--blue);
    border-radius: 8px;
    font-weight: 500;
    color: var(--white);
    transition: .3s all ease-in;
    fill: var(--white);
    stroke: var(--white);
}



.button:hover {
    background-color: transparent;
    outline: 1px solid rgb(139, 141, 152);
    color: var(--blue);
    fill: var(--blue);
}

.button:disabled {
    background: rgb(193, 208, 255);
}


.button__count {
    min-width: 21px;
    min-height: 21px;
    background-color: var(--white);
    color: var(--blue);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    transition: .4s all linear;
    font-weight: 500;
    padding: 2.5px 7px;
    line-height: 0;
}



.button:hover .button__count {
    background-color: var(--blue);
    color: var(--white);
}

.button__setting-icon {
    display: none;
}

@media (max-width:480px) {


    .button.button-setting {
        width: 48px;
        flex-shrink: 0;
        padding: 0;
    }

    .button-setting>*:not(.button__setting-icon) {
        display: none;
    }

    .button__setting-icon {
        display: block;
        flex-shrink: 0;

    }
}