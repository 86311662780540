.results-filter__button {
    padding: 0 16.5px;
}

.results-filter__inner {
    display: grid;
    grid-template-columns: auto auto 1fr;
    gap: 23px 20px;
    align-items: center;
}

.results-filter__title {
    font-size: 24px;
    font-weight: 700;
}

.results-filter__count {
    font-size: 14px;
    font-family: 'SF';
    color: var(--gray);
    font-weight: 400;
    margin-top: 4px;
}

.results-filter__selects {
    margin-left: auto;
    display: flex;
    gap: 0 34px;
}

.results-filter__items {
    display: flex;
    gap: 16px 8px;
    flex-wrap: wrap;
    grid-column: 1 / 4;
}

.results-filter__item {
    padding: 8px 10px;
    display: flex;
    gap: 0 4px;
    align-items: center;
    border-radius: 6px;
    background-color: rgb(240, 240, 243);
    color: rgb(31, 45, 92);
    font-size: 14px;
    font-weight: 500;
}

.filter {

    background-color: var(--page);
    z-index: 8;

}

.filter__content {
    width: 332px;
    border: 1px solid rgb(224, 225, 230);
    border-radius: 16px;
    padding: 20px;
    background: rgb(252, 252, 253);
}

@media (max-width: 1100px) {
    .results-filter__selects {
        grid-row: 2 / 3;
        grid-column: 1 /3;
    }
}

@media (max-width:480px) {
    .results-filter__inner {
        gap: 16px 8px;
        align-items: flex-start;
    }

    .results-filter__selects {
        display: none;
    }

    .results-filter__items {
        grid-column: 1 / 2;
    }

    .results-filter__button {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }

    .results-filter__item:nth-child(n+5) {
        display: none;
    }

    .results-filter__title {
        color: rgb(28, 32, 36);
        font-size: 28px;
    }

    .results-filter__count {
        margin-top: 16px;
    }
}